import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import BeABloomie from "../components/beBloomie"
import CTAInlink from "../components/CTAInLink"
import Img from "gatsby-image"
import SEO from "../components/seo"

const Philosophy = props => {
  const philosophies = [
    {
      name: "Passion Based Learning",
      description:
        "The school believes that a student cannot be defined by his/her successes in math and sciences. What matters the most is being educated, happy and accomplished in life. This can be best ensured if individuals can follow passion starting from an early age. We offer structured programs that let students engage in the field of passion areas like sports, mathematics, computers starting from as early as fourth grade.",
    },
    {
      name: "Education For All",
      description:
        "We believe that education should be accessible and available for people from all walks of life, irrespective of social and economic status. Our scholarships offered in collaboration with Bloom Nepal Foundation provide opportunities for talented individuals from economically disadvantaged families to pursue education.",
    },
    {
      name: "Technology Driven",
      description:
        "In partnership with BloomED, we offer all students a Blended learning opportunity.",
    },
    {
      name: "Sustainability",
      description:
        "Preserving our environment and ecosystem should start from young age. We run several activities that are aimed at making our students responsible for mother nature.",
    },
  ]
  const [activeTab, setActiveTab] = useState(0)

  return (
    <div className="mt-2 lg:flex section-for-small-devices">
      <div id="philosophies" className="lg:w-2/5">
        <h2 className="section-head mb-2" id="values">
          Our <span className="">Values</span>
        </h2>
        <div className="lg:mt-8 flex flex-wrap lg:block ">
          {philosophies.map((item, index) => (
            <button
              onClick={() => setActiveTab(index)}
              key={index}
              className={`py-4 px-4 lg:my-4 my-2 mr-6 text-white button-outline block ${
                index === activeTab ? "bg-red-800" : "bg-blue-800"
              }`}
            >
              {item.name}
            </button>
          ))}
        </div>
      </div>
      <div className="mt-6 lg:w-3/5 lg:mt-12 lg:px-12">
        <h3 className="section-head text-lg text-red-800 ">
          {philosophies[activeTab].name}
        </h3>
        <p className="mt-2 lg:mt-8">{philosophies[activeTab].description} </p>
      </div>
    </div>
  )
}

export default function () {
  const imageData = useStaticQuery(graphql`
    query {
      hourOfCode: file(relativePath: { eq: "hour-of-code.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            srcSet
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      flyingDrone: file(relativePath: { eq: "flying-drone.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            srcSet
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      logoSmall: file(relativePath: { eq: "logo-bloom.png" }) {
        childImageSharp {
          fixed(height: 160) {
            aspectRatio
            base64
            src
            srcSet
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="About US"/>
      {/* <section>
        <Calendar />
      </section> */}
      <div className="px-4 py-16 w-full lg:border-l lg:border-b border-gray-600 md:text-center  md:pb-20 md:mt-16 lg:px-20">
        <div className="section-for-small-devices">
          <Img
            fluid={imageData.hourOfCode.childImageSharp.fluid}
            alt="Students in computer lab"
            draggable={false}
            className=""
          />
          <h2 className="mt-4 uppercase section-head text-4xl xl:text-5xl tracking-wide leading-snug text-gray-800">
            Our aim is to make <span className="">quality education</span>{" "}
            available to
            <span className="text-red-700"> everyone, everywhere</span> in{" "}
            <span className="">Nepal</span>
          </h2>
        </div>
      </div>
      <section className="lg:border-b lg:border-r border-gray-600">
        <div className="section-for-small-devices lg:flex lg:items-center">
          <div className="lg:w-2/5 lg:text-center pr-8">
            <div className="overflow-visible section-image-fix flex  flex-col items-start">
              <Img
                fixed={imageData.logoSmall.childImageSharp.fixed}
                alt="bloom nepal school logo shaped like a circle"
                draggable={false}
                className=""
              />
            </div>
            <h1 className="section-head text-2xl text-red-800 hidden">
              {" "}
              Bloom Nepal School{" "}
            </h1>
          </div>
          <div className="lg:w-3/5">
            <p className="mt-2  md:text-gray-900">
              Bloom Nepal School is a network of schools providing secondary
              school education in Nepal. Established in 2013 with the goal of
              creating passion-based learning institutions where students of all
              socioeconomic backgrounds could come together for a shared purpose
              of intellectual development, we have two schools to date, in
              Lalitpur and Itahari.
            </p>
          </div>
        </div>
      </section>
      <section className="lg:border-l lg:border-b border-gray-600">
        <Philosophy />
      </section>
      <section className="lg:border-r lg:border-b border-gray-600 ">
        <div className="section-for-small-devices lg:flex">
          <div className="lg:w-1/2 lg:pr-8">
            <h2 className="section-head">Facilities and Features</h2>
            <div className="mt-4 lg:hidden section-image-fix">
              <Img
                className=""
                fluid={imageData.flyingDrone.childImageSharp.fluid}
                alt="Students flying drones using remote controller"
                draggable={false}
              />
              <p className="caption">
                The Fly Beyond Dreams team teaching students to build and fly
                drones!{" "}
              </p>
            </div>
            <p className="mt-4">
              We believe that providing the right set of tools and infrastructure
              in creating a conducive environment is a key to student success.
            </p>
            <p className="mt-2">
              Therefore a hefty part of our investment goes towards creating
              resources that aid the creativity of our students.
            </p>
            <div className="text-left mt-12 lg:mt-0">
              <CTAInlink
                linkto="/facilities"
                text="Facilities"
                className="bg-red-800 hover:bg-red-900"
              />
            </div>
          </div>
          <div className="mt-4 lg:w-1/2 lg:pl-8 hidden lg:block">
            <Img
              className=""
              fluid={imageData.flyingDrone.childImageSharp.fluid}
              alt="Students flying drones using remote controller"
              draggable={false}
            />
            <p className="caption">
              The Fly Beyond Dreams team teaching students to build and fly
              drones!{" "}
            </p>
          </div>
        </div>
      </section>
      <section
        className="lg:border-l lg:border-b border-gray-600 "
        id="beyondBloom"
      >
        <div className="section-for-small-devices lg:flex lg:flex-row-reverse">
          <div className="lg:w-1/2 lg:pl-8">
            <h2 className="section-head">Educating Beyond Bloom</h2>
            <p className="mt-4">
              There is only so much we can expand Bloom physically. However, the
              mission to provide quality education is so important that this
              limitation shouldn’t stop us.
            </p>
            <p className="mt-2">
              Considering this, we have been working on BloomED, an online
              platform with hundreds of school-level courses centered on Nepali
              students designed by professionals.
            </p>
          </div>
          <div className="mt-8 lg:bg-gray-100 text-blue-800 lg:border-0   lg:mt-0 lg:pr-8  lg:w-1/2 lg:flex lg:justify-center lg:items-center lg:text-xl lg:text-gray-900">
            <a href="http://bloomedn.org/" className="hover:underline in-link">
              bloomnedn.org
            </a>
          </div>
        </div>
      </section>
      <section className="">
        <BeABloomie />
      </section>
    </Layout>
  )
}